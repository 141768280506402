import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://127.0.0.1:3000',
  baseURL: 'https://zawiyah-backend-v3.onrender.com',
  headers: {
    'Content-Type': 'application/json',
    'accept': 'application/json',
  }
})

export default api
